import React, { useState, useEffect } from 'react';
import './App.css';
import Timer from './Timer';

function App() {
  const [numSpeakers, setNumSpeakers] = useState(6);
  const [timeBetweenSpeakers, setTimeBetweenSpeakers] = useState(10);
  const [running, setRunning] = useState(false);
  const [delay, setDelay] = useState(false);
  const [renderreset, setRenderReset] = useState(false);
  const [renderloadingcountdown, setRenderLoadingCountdown] = useState(false);

  useEffect(() => {
    document.title = "Auto Extemp";
  }, []);

  const handleNumSpeakersChange = (event) => {
    setNumSpeakers(Number(event.target.value));
  };

  const handleTimeBetweenSpeakersChange = (event) => {
    setTimeBetweenSpeakers(Number(event.target.value));
  };

  const handleStart = () => {
    setRunning(true);
    setRenderLoadingCountdown(true)
    setTimeout(() => {
      setDelay(true)
      setRenderReset(true)
      setRenderLoadingCountdown(false)
    }, 2000)
  };

  const handleReset = () => {
    setRunning(false);
    setDelay(false)
    setRenderReset(false)
  };


  const speakers = [];
  const prepTime = 30;

  for (let i = 0; i < numSpeakers; i++) {
    speakers.push({
      key: i,
      prepTime,
      startTime: new Date(new Date().getTime() + timeBetweenSpeakers * 60 * 1000 * i)
    });
  }

  return (
    <div className="App">
      <header>
        <h1>Extemp Draw Timer</h1>
      </header>
      
      <div className="timer-grid">
        {speakers.map((speaker, index) => (
              <Timer
                index={index}
                running={running}
                delay = {delay}
                startTime={speaker.startTime}
                prepTime={prepTime}
                timeBetweenSpeakers={timeBetweenSpeakers}
              />
        ))}
      </div>
      <div className="inputs" style={{ display: running ? 'none' : 'flex' }}>
        <div>
          Number of speakers in largest section:&nbsp;
          <input
            type="number"
            value={numSpeakers}
            onChange={handleNumSpeakersChange}
            min="4"
            max="300"
          />
        </div>
        <div>
          Time between speakers (minutes):&nbsp;
          <input
            type="number"
            value={timeBetweenSpeakers}
            onChange={handleTimeBetweenSpeakersChange}
            min="1"
            max="15"
          />
        </div>
        <div>
          <button className="start" onClick={handleStart}>Start</button>
        </div>
      </div>
      <div className="inputs" style={{ display: renderloadingcountdown? 'flex' : 'none' }}>

        <div>
        Extemp Draw will start momentarily.
        </div>
      </div>
      <div className="inputs" style={{ display: renderreset ? 'flex' : 'none' }}>
        <div>
          &nbsp;
        </div>
        <div>
        <button className="reset" onClick={handleReset}>Reset</button>
        </div>
        <div>
          &nbsp;
        </div>
        
      </div>
    </div>
  );
}

export default App;
