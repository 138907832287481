import React from "react";
import "./App.css";

class Timer extends React.Component {
  state = {
    timeSinceStart: 0,
  };

  componentDidMount() {
    if (this.props.running) {
      const { startTime } = this.props;
      const timeSinceStart = (new Date() - startTime) / 1000 / 60;
      this.setState({ timeSinceStart });
      this.interval = setInterval(() => {
        this.setState({
          timeSinceStart: (new Date() - startTime) / 1000 / 60,
        });
      }, 20);
    }
  }
  
    componentWillUnmount() {
      clearInterval(this.interval);
    }
  
    componentDidUpdate(prevProps) {
      if (this.props.running && !prevProps.running && this.props.prepTime > 0) {
        this.interval = setInterval(() => {
          this.setState({
            timeSinceStart: (new Date() - this.props.startTime) / 1000 / 60,
          });
        }, 20);
      } else if (!this.props.running && prevProps.running) {
        clearInterval(this.interval);
      }
    }
  
    formatTime(time) {
      const minutes = Math.floor(time);
      const seconds = Math.floor((time - minutes) * 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }

  render() {
    const { timeSinceStart } = this.state;
    const { index, delay, prepTime, timeBetweenSpeakers } = this.props;

    if (!delay) {
      if (index === 0) {
        return (
          <div
            style={{
              backgroundColor: "rgba(147,200,120,0.6)",
              borderRadius: 15,
            }}
          >
            <div>Speaker {index + 1}</div>
            <div style={{ textAlign:"center" }}>Get ready to draw.</div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "rgba(255,255,255,0.6)",
              borderRadius: 15,
            }}
          >
            <div>Speaker {index + 1}</div>
            <div>{this.formatTime((index) * timeBetweenSpeakers)}</div>
          </div>
        );
      }
    } else if (timeSinceStart <= 0) {
      return (
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.6)",
            borderRadius: 15,
          }}
        >
          <div>Speaker {index + 1}</div>
          <div>{this.formatTime(-timeSinceStart)}</div>
        </div>
      );
    } else if (timeSinceStart <= prepTime) {
      return (
        <div
          style={{
            backgroundColor: "rgba(147,200,120,0.6)",
            borderRadius: 15,
          }}
        >
          <div>Speaker {index + 1}</div>
          <div>{this.formatTime(prepTime - timeSinceStart)}</div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "rgba(136,8,8,0.6)",
            borderRadius: 15,
          }}
        >
          <div>Speaker {index + 1}</div>
          <div style={{ textAlign:"center" }}>Time's up!<br></br>Go to your room</div>
        </div>
      );
    }
  }
}

export default Timer;
